.wrapper {
    margin: 10px;
    padding: 1rem;
}

.stockBox {
    border: 0.5px solid black;

    border-radius: 15px;
    margin: 0px 10px;
}

.stockName {
    text-align: center;
    font-size: 1.2rem;
    color: black;
    padding: .5rem;
    margin: 0rem;
}

.stockLive {
    color: black;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    margin: 0rem;
}

.stockGrow {
    color: green;
    font-weight: 500;
    font-size: 0.8rem;
    text-align: center;
    margin: 0rem;
}

.stockDown {
    color: red;
    font-weight: 500;
    font-size: 0.8rem;
    text-align: center;
    margin: 0rem;
}

.stockDivisions {
    border: 0.5px solid black;

    border-radius: 5px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);
    margin-top: 10px;
}

.txtAdv {
    color: green;
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
}

.txtDecline {
    color: red;
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
}

.txtUnChange {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
}

.stockDetails {

    padding: 0.5rem;
    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);

}

.tabledata {
    padding: 0px 0.3rem !important;
    font-size: 14px !important;
}