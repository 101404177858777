/* .tableContainer {
 
  background-color: #ffffff;

  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  

}

.trendWrapper{
  border-width: 1px;
  border-color: black;
 width: 80px;
padding:5px 10px;
background-color: black;
color: white;
border-radius: 5px;
}

.tableTitle{
font-size: 16px !important;
color: red !important;
font-weight: 800;
} */

/* components/dashboard.module.css */
.container {
  padding: 16px;
}

.tabs {
  border-bottom: 1px solid #e0e0e0;
}

.stockInfo {
  margin-top: 16px;
}

.stockDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.chartContainer {
  height: 200px;
  background-color: #f5f5f5;
  padding: 16px;
}



/* loader */
.spinnerWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f4f4ef;
  top: 0px;
  left: 0px;
  z-index: 9999;
  opacity: .4;
  pointer-events: visible;




}

.spinner {
  position: relative;
  top: 50%;
  left: 50%;


}


.ldsRing {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #29ed0b;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0bfe50 transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.tableContainer {

  background-color: #ffffff;

  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);


}

.trendWrapper {
  border-width: 1px;
  border-color: black;
  width: 80px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  border-radius: 5px;
}

.tableTitle {
  font-size: 16px !important;
  color: red !important;
  font-weight: 800;
}


.navbar {


  padding: 1rem;
  z-index: 1000;
  display: flex;
}

.leftDiv,
.rightDiv,
.middleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: 0.5px solid black;
  border-radius: 10px;
  margin: 0px 10px;
  padding: 10px;
}

.leftDiv {
  width: 25%;
  display: flex;

}

.middleDiv {
  width: 50%;
}

.rightDiv {
  width: 25%;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar a:hover {
  background-color: #575757;
  border-radius: 5px;
}

.redCircle {
  width: 20px;
  /* Diameter = 2 * radius */
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

.greenCircle {
  width: 20px;
  /* Diameter = 2 * radius */
  height: 20px;
  background-color: green;
  border-radius: 50%;
}

.connectionText {
  color: black;
  margin: 0px 10px;
  padding: 0px;
}

/* new design top section */
.wrapper {
  margin: 10px;
  padding: 1rem;
}

.stockBox {
  border: 0.5px solid black;

  border-radius: 15px;
}

.stockName {
  text-align: center;
  font-size: 1.2rem;
  color: black;
  padding: .5rem;
  margin: 0rem;
}

.stockLive {
  color: black;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin: 0rem;
}

.stockGrow {
  color: green;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  margin: 0rem;
}

.stockDivisions {
  border: 0.5px solid black;

  border-radius: 5px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  margin-right: calc(var(--bs-gutter-x)* .5);
  margin-left: calc(var(--bs-gutter-x)* .5);
  margin-top: 10px;
}

.txtAdv {
  color: green;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
}

.txtDecline {
  color: red;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
}

.txtUnChange {
  color: gray;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
}

.stockDetails {

  padding: 0.5rem;
  margin-right: calc(var(--bs-gutter-x)* .5);
  margin-left: calc(var(--bs-gutter-x)* .5);

}



/* end */